/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import './src/css/custom.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-slideshow-image/dist/styles.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const shouldUpdateScroll = ({
                                       routerProps: { location },
                                       getSavedScrollPosition,
                                   }) => {
    const { pathname } = location
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [`/fonctionnalites/suivi-de-tresorerie`, `/tarifs`, `/fonctionnalites/previsions-de-tresorerie`,`/fonctionnalites/tresorerie-collaborative`,`/fonctionnalites/integrations`]
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1) {
        window.scrollTo(0, 0)
    }

    return false
}

  

